/* DateModal.module.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.datepicker {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}
.buttons button {
  font-size: 18px;
  border: 2px #acd1a3 solid;
  outline: none;
  background: rgb(255, 255, 255);
  border-radius: 12px;
}
.buttons button:hover {
  background-color: #acd1a3;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}
