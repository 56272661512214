.product-list-container {
  font-family: "Rubik";
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  text-align: left;
  background-color: rgba(255, 255, 255, 1) !important;
  border-radius: 24px;
  border-color: rgba(205, 236, 198, 1) !important;
  border: 1px solid;
  box-shadow: 0px 24px 36.6px 0px rgba(0, 0, 0, 0.1),
    0px 0px 68.1px 0px rgba(0, 0, 0, 0.09),
    0px 212px 127px 0px rgba(228, 228, 212, 0.05),
    0px 378px 151px 0px rgba(228, 228, 212, 0.01),
    0px 590px 165px 0px rgba(228, 228, 212, 0);
}
SearchBar {
  width: 100%;
}
.product-list-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 16px;
  background-color: rgba(255, 255, 255, 1) !important;
  border-radius: 16px;
  border-color: rgba(205, 236, 198, 1) !important;
  border: 1px solid;
}
.thead-row {
  color: rgba(16, 38, 13, 0.7);
}
.table-col {
  border-right: 1px solid rgba(205, 236, 198, 1) !important;
  padding: 4px !important;
  text-align: center !important;
  align-items: center;
  font-family: "Rubik" !important;
  font-size: 16px !important;
  overflow: hidden;
}
.table-col-text-input {
  padding: 0 !important;
}
.table-col input {
  padding: 0 0 0 15px;
  border: none;
  text-align: center !important;
}
.table-col input:focus {
  box-shadow: none !important;
}
.table-col input:active {
  text-align: center !important;
}
.table-col:last-child {
  border-right: none !important; /* Remove the border from the last and penultimate columns */
}
.edit-button {
  border: none;
  background-color: inherit;
  color: #acd1a3;
}
.edit-button:hover,
.edit-button:active {
  border: none;
  color: rgba(84, 128, 84, 1);
}
.delete-button {
  border: none;
  background-color: inherit;
  color: rgba(255, 156, 156, 1);
}
.delete-button:hover,
.delete-button:active {
  border: none;
  color: red;
}
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  margin: 0 10px;
  font-size: 18px;
  border: 2px #acd1a3 solid;
  outline: none;
  border-radius: 12px;
  background: rgb(255, 255, 255);
}

.pagination-controls button:hover {
  background-color: #acd1a3;
}
.product-search-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.add-product-btn-wrapper {
  border: none;
  background-color: inherit;
}
.add-product-btn{
  color: #548054;
}