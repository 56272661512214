.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.modal-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.cancel-button:hover,
.export-button:hover {
  background-color: #acd1a3;
}

.cancel-button,
.export-button {
  font-size: 18px;
  border: 2px #acd1a3 solid;
  outline: none;
  background: rgb(255, 255, 255);
  border-radius: 12px;
}

.export-ration-button {
  padding: 16px 32px;
  border: none;
  background: rgba(84, 128, 84, 1);
  border-radius: 16px;
  width: 100%;
  color: white;
  font-family: "Rubik";
  font-weight: 600;
  outline: none;
}
