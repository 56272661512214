.home-layout {
  background-image: url("../../../src/images/background.svg");
  background-size: 100% 100%;
  min-height: 100vh; /* Ensure it takes up at least the full height of the viewport */
  width: 100%;
  padding: 32px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  box-sizing: border-box; /* Ensure padding is included in height calculation */
}
