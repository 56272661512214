.container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.createProfileForm {
  margin: 200px;
  display: flex;
  flex-direction: column;
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 500;
  gap: 16px;
}

.createProfileForm input {
  width: 300px;
  line-height: 42px;
  text-align: center;
  border: 1px solid rgba(84, 128, 84, 1);
  border-radius: 16px;
}

.createProfileForm input:focus {
  outline: none;
}

.createProfileButton {
  background-color: rgba(84, 128, 84, 1);
  line-height: 42px;
  color: aliceblue;
  border: none;
  width: 100%;
  border-radius: 16px;
}
