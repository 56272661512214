@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

.header {
  display: flex;
  inline-size: 100%;
  padding: 16px 24px;
  justify-content: space-between; /* Aligns children (left and right side-items) */
  align-items: center;
  color: black;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(205, 236, 198, 1);
  border-radius: 24px;
  gap: 5px;
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
}

.side-items {
  display: flex;
  align-items: center;
}

.left-group,
.right-group {
  display: flex;
  align-items: center;
}

.left-group {
  justify-content: flex-start; /* Align content to the start (left) */
  gap: 20px; /* Space between items */
}

.right-group {
  justify-content: flex-end; /* Align content to the end (right) */
  gap: 8px; /* Space between items */
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-buttons {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adjust as needed */
  margin: 0;
}

.nav {
  flex: 1; /* Allows nav to take up available space */
  display: flex;
  justify-content: center; /* Centers the nav horizontally */
}

.button {
  flex: 1 1 auto; /* Allow the button to grow and shrink with available space */
  min-block-size: 48px;
  max-inline-size: 266px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(205, 236, 198, 1);
  border-radius: 16px;
  font-size: 18px;
  text-decoration: none;
  padding: 0 16px; /* Add padding for better spacing */
  color: rgb(6, 33, 2);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.button-active:focus {
  outline: none;
}

@media (max-width: 768px) {
  .header {
    padding: 8px 16px;
  }
}

@media (min-width: 768px) {
  .button {
    font-size: 18px; /* Adjust font size */
  }
}

@media (min-width: 1200px) {
  .button {
    font-size: 20px; /* Adjust font size */
  }
}

@media (min-width: 1600px) {
  .button {
    font-size: 22px; /* Adjust font size */
  }
}

@media (min-width: 1800px) {
  .button {
    font-size: 24px; /* Adjust font size */
  }
}

.button-active {
  background-color: rgba(205, 236, 198, 1);
}

.a:hover {
  text-decoration: underline;
}

.profile-dropdown {
  color: rgba(6, 33, 2, 1);
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  text-align: left;
  gap: 8px;
}
.profile-dropdown a:hover {
  text-decoration: underline;
}

.profile-dropdown .option:active {
  background-color: rgba(84, 128, 84, 1);
  font-family: "Rubik";
  font-weight: 400;
}

.rounded-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid rgba(84, 128, 84, 1);
  object-fit: cover;
}
.profile-dropdown a::after {
  display: none;
}
.profile-selection-form {
  display: flex;
  max-width: 220px;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.profile-selection-form.open {
  border-radius: 0px !important;
}
.profile-selection-form.closed {
  border-radius: 16px !important;
}
.exit-button {
  min-inline-size: 22px;
  color: rgba(84, 128, 84, 1);
  cursor: pointer;
}
.languageSelector {
  position: relative;
  display: inline-block;
  min-inline-size: 55px;
  outline: none;
}

.dropdownToggle {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
}

.flagButton {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.flagButton img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.flagButton:hover {
  background-color: #f0f0f0;
}
