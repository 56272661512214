.search__header {
  width: 100%;
}
.search {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 4px;
  border-radius: 16px;
  border-color: rgba(205, 236, 198, 1) !important;
  border: 1px solid;
}
.search__input {
  flex-grow: 1;
  border: none;
}
.search__input::placeholder {
  color: rgba(16, 38, 13, 0.7);
}
.search__input:focus {
  outline: none;
}
.search__icon {
  color: rgba(84, 128, 84, 1);
  border: none;
  background-color: inherit;
}
.search__button {
  color: rgba(246, 246, 246, 1);
  font-family: "Rubik";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  gap: 10px;
  padding: 7px 24px;
  border: none;
  background-color: rgba(84, 128, 84, 1);
  outline: none;
}
